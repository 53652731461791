<template>
  <icon-base :width="size + 'px'" :fill="color">
    <g>
      <path class="st0" d="M142.6,27.6H57.4c-7.8,0-14.2,6.3-14.2,14.2v142.1c0,7.8,6.3,14.2,14.2,14.2h85.3c7.8,0,14.2-6.3,14.2-14.2   V41.8C156.8,34,150.5,27.6,142.6,27.6z M129.6,102.3L83,168.1c-0.4,0.5-1.3,0.1-1.1-0.5L93,123.7c0.1-0.4-0.2-0.8-0.6-0.8H70.9   c-0.4,0-0.7-0.4-0.6-0.7l11.9-55.5c0.1-0.3,0.3-0.5,0.6-0.5h28.4c0.4,0,0.7,0.4,0.6,0.8l-9.9,33.5c-0.1,0.4,0.2,0.8,0.6,0.8h26.5   C129.6,101.4,129.9,101.9,129.6,102.3z"/>
      <path class="st0" d="M128.2,4.4c0-1.4-1.1-2.5-2.5-2.5H74.3c-1.4,0-2.5,1.1-2.5,2.5V21h56.4V4.4z"/>
    </g>
  </icon-base>
</template>

<script>
export default {
  name: 'iconBattery',
  components: {
    iconBase: () => import('@/common/components/iconBase.vue')
  },
  props: {
    size: {
      type: Number,
      default: 20
    },
    color: {
      type: String,
      default: ''
    },
    enableBackground: {
      type: Boolean
    }
  }
}
</script>

<style scoped>
.st1 {
  fill: #ffffff;
}
.st2 {
  clip-path: url(#SVGID_2_);
}
.st3 {
  fill: #ea3838;
}
.st4 {
  fill: #72bf58;
}
</style>

